
import { Component, Vue, Watch } from "vue-property-decorator";
import ToastService from "../../services/helper/ToastService";
import i18n from "../../plugins/i18n";
import draggable from "vuedraggable";

import SporteventService from "../../services/sportevent/SporteventService";
import SporteventCompetitionService from "../../services/sportevent/SporteventCompetitionService";
import SporteventCompetition from "../../models/sportevent/SporteventCompetition";
import Sportevent from "../../models/sportevent/Sportevent";

import DisciplineService from "../../services/sporteventCalculation/DisciplineService";
import Discipline from "../../models/sporteventCalculation/Discipline";

@Component({
  components: {
    draggable,
  },
})
export default class SporteventCompetitionsComponent extends Vue {
  public sportevent: Sportevent = new Sportevent();
  // Loading
  public loading = true;
  // Table
  public fields = [
    {
      key: "competition",
      sortable: true,
      label: i18n.tc("labels.name"),
    },
    {
      key: "age",
      sortable: false,
      label: i18n.tc("labels.age"),
    },
    {
      key: "disciplines",
      sortable: false,
      label: i18n.tc("sporteventCalculation.disciplines"),
      class: "half-table",
    },
  ];
  public filter = "";
  public perPage = 20;
  public pageOptions = [5, 10, 20, 30, 50];
  public totalRows = 1;
  public currentPage = 1;

  public allDisciplines: Discipline[] = [];

  public dropDisciplines: Discipline[] = [];

  public idGlobal = 8;

  mounted(): void {
    DisciplineService.getAll(true).then((disciplines: Discipline[]) => {
      disciplines.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.allDisciplines = disciplines;
    });
  }

  async getAll(): Promise<void> {
    this.sportevent = SporteventService.get();
    if (this.sportevent.id !== undefined) {
      this.sportevent = await SporteventService.getWithApi(this.sportevent.id)
      if (this.sportevent.sporteventCompetitions) {
        this.totalRows = this.sportevent.sporteventCompetitions.length;
      }
      this.loading = false;
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(): void {
    this.getAll();
  }

  async saveDiscipline(
    sporteventCompetition: SporteventCompetition,
    getAll = true
  ): Promise<void> {
    sporteventCompetition = await SporteventCompetitionService.save(
      sporteventCompetition
    );

    if (sporteventCompetition) {
      ToastService.Success(i18n.tc("labels.saved"), "");
    }
    if (getAll) {
      this.getAll();
    }
  }

  cleanDropDisciplines(): void {
    this.dropDisciplines = [];
  }

  cloneDiscipline({ id }: { id: string }): Discipline {
    return new Discipline(this.allDisciplines.find((x) => x.id == id));
  }
}
